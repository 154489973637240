//router
import IndexRouters from "./router/index"

//scss
import "./assets/scss/hope-ui.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/rtl.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/customizer.scss"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

function App() {

  return (
    <div className="App">
      <IndexRouters />
    </div>
  );
}

export default App;
